<template>
            <!-- ========== HEADER ========== -->
    <header id="header" class="navbar navbar-expand-lg navbar-end navbar-absolute-top navbar-light navbar-show-hide" data-hs-header-options='{
            "fixMoment": 1000,
            "fixEffect": "slide"
          }'>
        <!-- Topbar -->
        <div class="container navbar-topbar">
            <nav class="js-mega-menu navbar-nav-wrap">
               

                <div id="topbarNavDropdown" class="navbar-nav-wrap-collapse collapse navbar-collapse navbar-topbar-collapse">
                    <div class="navbar-toggler-wrapper">
                        <div class="navbar-topbar-toggler d-flex justify-content-between align-items-center">
                            <span class="navbar-toggler-text small">Topbar</span>

                            <!-- Toggler -->
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#topbarNavDropdown" aria-controls="topbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <i class="bi-x"></i>
              </button>
                            <!-- End Toggler -->
                        </div>
                    </div>

                    
                </div>
            </nav>
        </div>
        <!-- End Topbar -->

        <div class="container">
            <nav class="js-mega-menu navbar-nav-wrap">
                <!-- Default Logo -->
                <a class="navbar-brand" href="/index.html" aria-label="Front">
         
<svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 0 1594 500.5">
  <text id="SENT" transform="translate(0 389.5)" fill="#2000ac" font-size="400" font-family="Xirod-Regular, Xirod"><tspan x="0" y="0">SENT</tspan></text>
  <line id="Line_1" data-name="Line 1" x2="355" transform="translate(413.5 20.5)" fill="none" stroke="#ffd803" stroke-width="41"/>
</svg>






        </a>
                <!-- End Default Logo -->

                <!-- Toggler -->
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-default">
            <i class="bi-list"></i>
          </span>
          <span class="navbar-toggler-toggled">
            <i class="bi-x"></i>
          </span>
        </button>
                <!-- End Toggler -->

                <!-- Collapse -->
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <div class="navbar-absolute-top-scroller">
                        <ul class="navbar-nav">
                            <!-- Landings -->
                            <li class="hs-has-mega-menu nav-item">
                                <a id="landingsMegaMenu" class="hs-mega-menu-invoker nav-link  active" href="#">
                                    მთავარი
                                </a>

                               
                            </li>
                            <li class="hs-has-mega-menu nav-item">
                                <a id="landingsMegaMenu" class="hs-mega-menu-invoker nav-link  active" href="#">
                                    ჩვენ შესახებ
                                </a>

                               
                            </li>
                            <li class="hs-has-mega-menu nav-item">
                                <a id="landingsMegaMenu" class="hs-mega-menu-invoker nav-link  active" href="#">
                                    პაკეტები
                                </a>

                               
                            </li>
                             <li class="hs-has-mega-menu nav-item">
                                <a id="landingsMegaMenu" class="hs-mega-menu-invoker nav-link  active" href="#">
                                    ბლოგი
                                </a>

                               
                            </li>
                             <li class="hs-has-mega-menu nav-item">
                                <a id="landingsMegaMenu" class="hs-mega-menu-invoker nav-link  active" href="#">
                                    შესვლა
                                </a>

                               
                            </li>
                            
                            
                            

                            <!-- Button -->
                            <li class="nav-item">
                                <a class="btn btn-primary btn-transition" href="https://themes.getbootstrap.com/product/front-multipurpose-responsive-template/" target="_blank">
                                რეგისტრაცია
                                </a>
                            </li>
                            <!-- End Button -->
                        </ul>
                    </div>
                </div>
                <!-- End Collapse -->
            </nav>
        </div>
    </header>

    <!-- ========== END HEADER ========== -->

</template>

<script>
export default {
  name: 'HeaderTop',
  mounted() {
    (function() {
           // INITIALIZATION OF HEADER
           // =======================================================
           new HSHeader('#header').init()


         


           // INITIALIZATION OF SHOW ANIMATIONS
           // =======================================================
           new HSShowAnimation('.js-animation-link')


           // INITIALIZATION OF BOOTSTRAP VALIDATION
           // =======================================================
           HSBsValidation.init('.js-validate', {
               onSubmit: data => {
                   data.event.preventDefault()
                   alert('Submited')
               }
           })


           // INITIALIZATION OF BOOTSTRAP DROPDOWN
           // =======================================================
           HSBsDropdown.init()


           // INITIALIZATION OF GO TO
           // =======================================================
           new HSGoTo('.js-go-to')


           // INITIALIZATION OF AOS
           // =======================================================
           AOS.init({
               duration: 650,
               once: true
           });


           // INITIALIZATION OF SWIPER
           // =======================================================
           var swiper = new Swiper('.js-swiper-software-hero', {
               preloaderClass: 'custom-swiper-lazy-preloader',
               slidesPerView: 1,
               pagination: {
                   el: '.js-swiper-software-hero-pagination',
                   dynamicBullets: true,
                   clickable: true,
               },
               breakpoints: {
                   380: {
                       slidesPerView: 2,
                       spaceBetween: 15,
                   },
                   580: {
                       slidesPerView: 3,
                       spaceBetween: 15,
                   },
                   768: {
                       slidesPerView: 4,
                       spaceBetween: 15,
                   },
                   1024: {
                       slidesPerView: 5,
                       spaceBetween: 30,
                   },
               },
               on: {
                   'imagesReady': function(swiper) {
                       const preloader = swiper.el.querySelector('.js-swiper-software-hero-preloader')
                       preloader.parentNode.removeChild(preloader)
                   }
               }
           });


           // INITIALIZATION OF NAV SCROLLER
           // =======================================================
           new HsNavScroller('.js-nav-scroller')
       })()
  },
  components: {
    
  }
}
</script>
