<template>
  <div>
    
    <HeaderTop/>
    <main id="content" role="main">
        <!-- Hero -->
        <div class="gradient-y-sm-primary position-relative zi-2 overflow-hidden">
            <div class="container position-relative content-space-t-3 content-space-t-lg-4 content-space-b-2 m-mb-10">
                <!-- Heading -->
                <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9 m-mt-10 ">
                    <h1 class="display-4 mb-3 mt-5">დაზოგე მეტი დრო</h1>
                    <p class="lead">აღმოაჩინე ინვოისის შექმნის კომფორტული გამოცდილება!</p>
                
                </div>
                <!-- End Title & Description -->

             
                <!-- SVG Shape -->
                <figure class="position-absolute zi-n1" style="top: -35rem; left: 50rem; width: 62rem; height: 62rem;">
                    <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 260 260">
                    <circle fill="#e7eaf3" opacity=".7" cx="130" cy="130" r="130"/>
                </svg>
                </figure>
                <!-- End SVG Shape -->

                <!-- SVG Shape -->
                <figure class="position-absolute zi-n1" style="top: 15rem; left: 70rem; width: 20rem; height: 20rem;">
                    <img class="img-fluid" src="/assets/svg/components/circled-stripe.svg" alt="Image Description">
                </figure>
                <!-- End SVG Shape -->

                <!-- SVG Shape -->
                <figure class="position-absolute zi-n1" style="bottom: 11rem; left: -3rem; width: 15rem; height: 15rem;">
                    <img class="img-fluid" src="/assets/svg/components/dots.svg" alt="Image Description">
                </figure>
                <!-- End SVG Shape -->
            </div>

            <!-- Shape -->
            <div class="shape shape-bottom zi-1">
                <svg width="3000" height="500" viewBox="0 0 3000 500" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 500H3000V0L0 500Z" fill="#fff"/>
        </svg>
            </div>
            <!-- End Shape -->
        </div>
        <!-- End Hero -->

        <!-- Icon Blocks -->
        <div class="overflow-hidden">
            <div class="container content-space-t-2 content-space-t-lg-3 content-space-b-1 content-space-b-lg-3">
                <!-- Heading -->
                <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                    <h2>Features built for scale</h2>
                    <p>Get insights to dig down into what's powering your growth the most.</p>
                </div>
                <!-- End Heading -->

                <div class="row">
                    <div class="col-lg-4 d-none d-lg-block">
                        <!-- Card -->
                        <div class="position-relative pe-lg-4">
                            <a class="card card-transition shadow-none bg-img-start" href="#" style="background-image: url(/assets/img/400x500/img26.jpg); min-height: 27rem;">
                                <div class="card-body">
                                    <h4 class="card-title">Adobe Ai</h4>
                                    <p class="card-text text-body">Access to the Adobe Illustrator techniques</p>
                                </div>

                                <div class="card-footer pt-0">
                                    <span class="card-link">Browse tools <i class="bi-chevron-right small ms-1"></i></span>
                                </div>
                            </a>

                            <!-- SVG Shape -->
                            <div class="position-absolute bottom-0 start-0 zi-n1 mb-n7 ms-n7" style="width: 12rem;">
                                <img class="w-100" src="/assets/svg/components/dots-lg.svg" alt="SVG">
                            </div>
                            <!-- End SVG Shape -->
                        </div>
                        <!-- End Card -->
                    </div>
                    <!-- End Col -->

                    <div class="col-lg-8">
                        <div class="row">
                            <div class="col-sm-6 mb-3 mb-sm-7">
                                <!-- Icon Blocks -->
                                <div class="pe-lg-6">
                                    <span class="svg-icon text-primary mb-4">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M10.9607 12.9128H18.8607C19.4607 12.9128 19.9607 13.4128 19.8607 14.0128C19.2607 19.0128 14.4607 22.7128 9.26068 21.7128C5.66068 21.0128 2.86071 18.2128 2.16071 14.6128C1.16071 9.31284 4.96069 4.61281 9.86069 4.01281C10.4607 3.91281 10.9607 4.41281 10.9607 5.01281V12.9128V12.9128Z" fill="#035A4B"/>
                    <path d="M12.9607 10.9128V3.01281C12.9607 2.41281 13.4607 1.91281 14.0607 2.01281C16.0607 2.21281 17.8607 3.11284 19.2607 4.61284C20.6607 6.01284 21.5607 7.91285 21.8607 9.81285C21.9607 10.4129 21.4607 10.9128 20.8607 10.9128H12.9607V10.9128Z" fill="#035A4B"/>
                    </svg>

                  </span>

                                    <h3 class="h4">Smart Dashboards</h3>
                                    <p>This is where we really begin to visualize your napkin sketches and make them into beautiful pixels.</p>
                                </div>
                                <!-- End Icon Blocks -->
                            </div>

                            <div class="col-sm-6 mb-3 mb-sm-7">
                                <!-- Icon Blocks -->
                                <div class="pe-lg-6">
                                    <span class="svg-icon text-primary mb-4">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M16 0.200012H4C1.8 0.200012 0 2.00001 0 4.20001V16.2C0 18.4 1.8 20.2 4 20.2H16C18.2 20.2 20 18.4 20 16.2V4.20001C20 2.00001 18.2 0.200012 16 0.200012ZM15 10.2C15 10.9 14.8 11.6 14.6 12.2H18V16.2C18 17.3 17.1 18.2 16 18.2H12V14.8C11.4 15.1 10.7 15.2 10 15.2C9.3 15.2 8.6 15 8 14.8V18.2H4C2.9 18.2 2 17.3 2 16.2V12.2H5.4C5.1 11.6 5 10.9 5 10.2C5 9.50001 5.2 8.80001 5.4 8.20001H2V4.20001C2 3.10001 2.9 2.20001 4 2.20001H8V5.60001C8.6 5.30001 9.3 5.20001 10 5.20001C10.7 5.20001 11.4 5.40001 12 5.60001V2.20001H16C17.1 2.20001 18 3.10001 18 4.20001V8.20001H14.6C14.8 8.80001 15 9.50001 15 10.2Z" fill="#035A4B"/>
                    <path d="M12 1.40002C15.4 2.20002 18 4.80003 18.8 8.20003H14.6C14.1 7.00003 13.2 6.10003 12 5.60003V1.40002V1.40002ZM5.40001 8.20003C5.90001 7.00003 6.80001 6.10003 8.00001 5.60003V1.40002C4.60001 2.20002 2.00001 4.80003 1.20001 8.20003H5.40001ZM14.6 12.2C14.1 13.4 13.2 14.3 12 14.8V19C15.4 18.2 18 15.6 18.8 12.2H14.6V12.2ZM8.00001 14.8C6.80001 14.3 5.90001 13.4 5.40001 12.2H1.20001C2.00001 15.6 4.60001 18.2 8.00001 19V14.8V14.8Z" fill="#035A4B"/>
                    </svg>

                  </span>

                                    <h4>Control Center</h4>
                                    <p>Now that we've aligned the details, it's time to get things mapped out and organized.</p>
                                </div>
                                <!-- End Icon Blocks -->
                            </div>

                            <div class="col-sm-6 mb-3 mb-sm-0">
                                <!-- Icon Blocks -->
                                <div class="pe-lg-6">
                                    <span class="svg-icon text-primary mb-4">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725V8.725Z" fill="#035A4B"/>
                    <path opacity="0.3" d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z" fill="#035A4B"/>
                    </svg>

                  </span>

                                    <h4>Email Reports</h4>
                                    <p>We strive to embrace and drive change in our industry which allows us to keep our clients relevant.</p>
                                </div>
                                <!-- End Icon Blocks -->
                            </div>

                            <div class="col-sm-6">
                                <!-- Icon Blocks -->
                                <div class="pe-lg-6">
                                    <span class="svg-icon text-primary mb-4">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 19.5229C15 20.265 15.9624 20.5564 16.374 19.9389L22.2227 11.166C22.5549 10.6676 22.1976 10 21.5986 10H17V4.47708C17 3.73503 16.0376 3.44363 15.626 4.06106L9.77735 12.834C9.44507 13.3324 9.80237 14 10.4014 14H15V19.5229Z" fill="#035A4B"/>
                    <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M3 6.5C3 5.67157 3.67157 5 4.5 5H9.5C10.3284 5 11 5.67157 11 6.5C11 7.32843 10.3284 8 9.5 8H4.5C3.67157 8 3 7.32843 3 6.5ZM3 18.5C3 17.6716 3.67157 17 4.5 17H9.5C10.3284 17 11 17.6716 11 18.5C11 19.3284 10.3284 20 9.5 20H4.5C3.67157 20 3 19.3284 3 18.5ZM2.5 11C1.67157 11 1 11.6716 1 12.5C1 13.3284 1.67157 14 2.5 14H6.5C7.32843 14 8 13.3284 8 12.5C8 11.6716 7.32843 11 6.5 11H2.5Z" fill="#035A4B"/>
                    </svg>

                  </span>

                                    <h4>Forecasting</h4>
                                    <p>Staying focused allows us to turn every project we complete into something we love.</p>
                                </div>
                                <!-- End Icon Blocks -->
                            </div>
                        </div>
                    </div>
                    <!-- End Col -->
                </div>
                <!-- End Row -->
            </div>
        </div>
        <!-- End Icon Blocks -->

        <!-- CTA -->
        <div class="container">
            <div class="w-lg-75 mx-lg-auto">
                <div class="card card-sm overflow-hidden">
                    <!-- Card -->
                    <div class="card-body">
                        <div class="row justify-content-md-start align-items-md-center text-center text-md-start">
                            <div class="col-md offset-md-3 mb-3 mb-md-0">
                                <h4 class="card-title">Revolutionizing the way your team works!</h4>
                            </div>

                            <div class="col-md-auto">
                                <a class="btn btn-primary btn-transition" href="#">Get started</a>
                            </div>
                        </div>

                        <!-- SVG Shape -->
                        <figure class="w-25 d-none d-md-block position-absolute top-0 start-0 mt-n2">
                            <img class="card-img" src="/assets/svg/illustrations/apps.svg" alt="Image Description">
                        </figure>
                        <!-- End SVG Shape -->
                    </div>
                    <!-- End Card -->
                </div>
            </div>
        </div>
        <!-- End CTA -->

        <!-- Features -->
        <div class="container content-space-t-2 content-space-lg-3">
            <!-- Heading -->
            <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                <h2>Front makes designing easy and performance fast</h2>
            </div>
            <!-- End Heading -->

            <!-- Nav Scroller -->
            <div class="js-nav-scroller hs-nav-scroller-horizontal">
                <span class="hs-nav-scroller-arrow-prev" style="display: none;">
          <a class="hs-nav-scroller-arrow-link" href="javascript:;">
            <i class="bi-chevron-left"></i>
          </a>
        </span>

                <span class="hs-nav-scroller-arrow-next" style="display: none;">
          <a class="hs-nav-scroller-arrow-link" href="javascript:;">
            <i class="bi-chevron-right"></i>
          </a>
        </span>

                <!-- Nav -->
                <ul class="nav nav-segment nav-pills nav-fill mx-auto mb-7" id="featuresTab" role="tablist" style="max-width: 30rem;">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active" href="#featuresOne" id="featuresOne-tab" data-bs-toggle="tab" data-bs-target="#featuresOne" role="tab" aria-controls="featuresOne" aria-selected="true">App Marketplace</a>
                    </li>

                    <li class="nav-item" role="presentation">
                        <a class="nav-link" href="#featuresTwo" id="featuresTwo-tab" data-bs-toggle="tab" data-bs-target="#featuresTwo" role="tab" aria-controls="featuresTwo" aria-selected="false">Course</a>
                    </li>

                    <li class="nav-item" role="presentation">
                        <a class="nav-link" href="#featuresThree" id="featuresThree-tab" data-bs-toggle="tab" data-bs-target="#featuresThree" role="tab" aria-controls="featuresThree" aria-selected="false">Account Dashboard</a>
                    </li>
                </ul>
                <!-- End Nav -->
            </div>
            <!-- End Nav Scroller -->

            <!-- Tab Content -->
            <div class="tab-content" id="featuresTabContent">
                <div class="tab-pane fade show active" id="featuresOne" role="tabpanel" aria-labelledby="featuresOne-tab">
                    <!-- Devices -->
                    <div class="devices">
                        <!-- Mobile Device -->
                        <figure class="device-mobile rotated-3d-right">
                            <div class="device-mobile-frame">
                                <img class="device-mobile-img" src="/assets/img/407x867/img1.jpg" alt="Image Description">
                            </div>
                        </figure>
                        <!-- End Mobile Device -->

                        <!-- Browser Device -->
                        <figure class="device-browser">
                            <div class="device-browser-header">
                                <div class="device-browser-header-btn-list">
                                    <span class="device-browser-header-btn-list-btn"></span>
                                    <span class="device-browser-header-btn-list-btn"></span>
                                    <span class="device-browser-header-btn-list-btn"></span>
                                </div>
                                <div class="device-browser-header-browser-bar">www.htmlstream.com/front/</div>
                            </div>

                            <div class="device-browser-frame">
                                <img class="device-browser-img" src="/assets/img/1618x1010/img1.jpg" alt="Image Description">
                            </div>
                        </figure>
                        <!-- End Browser Device -->
                    </div>
                    <!-- End Devices -->
                </div>

                <div class="tab-pane fade" id="featuresTwo" role="tabpanel" aria-labelledby="featuresTwo-tab">
                    <!-- Devices -->
                    <div class="devices">
                        <!-- Mobile Device -->
                        <figure class="device-mobile rotated-3d-right">
                            <div class="device-mobile-frame">
                                <img class="device-mobile-img" src="/assets/img/407x867/img2.jpg" alt="Image Description">
                            </div>
                        </figure>
                        <!-- End Mobile Device -->

                        <!-- Browser Device -->
                        <figure class="device-browser">
                            <div class="device-browser-header">
                                <div class="device-browser-header-btn-list">
                                    <span class="device-browser-header-btn-list-btn"></span>
                                    <span class="device-browser-header-btn-list-btn"></span>
                                    <span class="device-browser-header-btn-list-btn"></span>
                                </div>
                                <div class="device-browser-header-browser-bar">www.htmlstream.com/front/</div>
                            </div>

                            <div class="device-browser-frame">
                                <img class="device-browser-img" src="/assets/img/1618x1010/img2.jpg" alt="Image Description">
                            </div>
                        </figure>
                        <!-- End Browser Device -->
                    </div>
                    <!-- End Devices -->
                </div>

                <div class="tab-pane fade" id="featuresThree" role="tabpanel" aria-labelledby="featuresThree-tab">
                    <!-- Devices -->
                    <div class="devices">
                        <!-- Mobile Device -->
                        <figure class="device-mobile rotated-3d-right">
                            <div class="device-mobile-frame">
                                <img class="device-mobile-img" src="/assets/img/407x867/img4.jpg" alt="Image Description">
                            </div>
                        </figure>
                        <!-- End Mobile Device -->

                        <!-- Browser Device -->
                        <figure class="device-browser">
                            <div class="device-browser-header">
                                <div class="device-browser-header-btn-list">
                                    <span class="device-browser-header-btn-list-btn"></span>
                                    <span class="device-browser-header-btn-list-btn"></span>
                                    <span class="device-browser-header-btn-list-btn"></span>
                                </div>
                                <div class="device-browser-header-browser-bar">www.htmlstream.com/front/</div>
                            </div>

                            <div class="device-browser-frame">
                                <img class="device-browser-img" src="/assets/img/1618x1010/img4.jpg" alt="Image Description">
                            </div>
                        </figure>
                        <!-- End Browser Device -->
                    </div>
                    <!-- End Devices -->
                </div>
            </div>
            <!-- End Tab Content -->
        </div>
        <!-- End Features -->

        <!-- Clients -->
        <div class="container content-space-2 content-space-b-lg-3">
            <!-- Heading -->
            <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-6">
                <h2>Trusted by Open Source, enterprise, and more than 33,000 of you</h2>
            </div>
            <!-- End Heading -->

            <div class="row justify-content-center text-center">
                <div class="col-4 col-sm-3 col-md-2 py-3">
                    <img class="avatar avatar-lg avatar-4x3 avatar-centered" src="/assets/svg/brands/amazon-dark.svg" alt="Logo">
                </div>
                <!-- End Col -->

                <div class="col-4 col-sm-3 col-md-2 py-3">
                    <img class="avatar avatar-lg avatar-4x3 avatar-centered" src="/assets/svg/brands/kaplan-dark.svg" alt="Logo">
                </div>
                <!-- End Col -->

                <div class="col-4 col-sm-3 col-md-2 py-3">
                    <img class="avatar avatar-lg avatar-4x3 avatar-centered" src="/assets/svg/brands/google-dark.svg" alt="Logo">
                </div>
                <!-- End Col -->

                <div class="col-4 col-sm-3 col-md-2 py-3">
                    <img class="avatar avatar-lg avatar-4x3 avatar-centered" src="/assets/svg/brands/airbnb-dark.svg" alt="Logo">
                </div>
                <!-- End Col -->

                <div class="col-4 col-sm-3 col-md-2 py-3">
                    <img class="avatar avatar-lg avatar-4x3 avatar-centered" src="/assets/svg/brands/shopify-dark.svg" alt="Logo">
                </div>
                <!-- End Col -->

                <div class="col-4 col-sm-3 col-md-2 py-3">
                    <img class="avatar avatar-lg avatar-4x3 avatar-centered" src="/assets/svg/brands/vidados-dark.svg" alt="Logo">
                </div>
                <!-- End Col -->

                <div class="col-4 col-sm-3 col-md-2 py-3">
                    <img class="avatar avatar-lg avatar-4x3 avatar-centered" src="/assets/svg/brands/capsule-dark.svg" alt="Logo">
                </div>
                <!-- End Col -->

                <div class="col-4 col-sm-3 col-md-2 py-3">
                    <img class="avatar avatar-lg avatar-4x3 avatar-centered" src="/assets/svg/brands/forbes-dark.svg" alt="Logo">
                </div>
                <!-- End Col -->

                <div class="col-4 col-sm-3 col-md-2 py-3">
                    <img class="avatar avatar-lg avatar-4x3 avatar-centered" src="/assets/svg/brands/business-insider-dark.svg" alt="Logo">
                </div>
                <!-- End Col -->

                <div class="col-4 col-sm-3 col-md-2 py-3">
                    <img class="avatar avatar-lg avatar-4x3 avatar-centered" src="/assets/svg/brands/hubspot-dark.svg" alt="Logo">
                </div>
                <!-- End Col -->

                <div class="col-4 col-sm-3 col-md-2 py-3">
                    <img class="avatar avatar-lg avatar-4x3 avatar-centered" src="/assets/svg/brands/layar-dark.svg" alt="Logo">
                </div>
                <!-- End Col -->
            </div>
            <!-- End Row -->
        </div>
        <!-- End Clients -->

        <!-- Subscribe -->
        <div class="container content-space-b-2 content-space-b-lg-3">
            <div class="w-md-75 w-lg-50 text-center mx-md-auto">
                <div class="row justify-content-lg-between">
                    <!-- Heading -->
                    <div class="mb-5">
                        <h2>Get started with Front</h2>
                    </div>
                    <!-- End Heading -->

                    <form>
                        <!-- Input Card -->
                        <div class="input-card input-card-sm border mb-3">
                            <div class="input-card-form">
                                <label for="subscribeForm" class="form-label visually-hidden">Enter email</label>
                                <input type="text" class="form-control form-control-lg" id="subscribeForm" placeholder="Enter email" aria-label="Enter email">
                            </div>
                            <button type="button" class="btn btn-primary btn-lg">Get started</button>
                        </div>
                        <!-- End Input Card -->
                    </form>

                    <p><a href="/page-terms.html">Terms &amp; conditions</a> applied.</p>
                </div>
            </div>
        </div>
        <!-- End Subscribe -->
    </main>
    <Footer/>





  </div>
</template>

<script>
import HeaderTop from '../components/HeaderTop.vue'
import Footer from '../components/Footer.vue'
export default {
  name: 'Home',
  mounted() {
   
  },
  components: {
    HeaderTop,
    Footer
  }
}
</script>
